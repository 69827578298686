import React, { useState, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { navigate } from "gatsby";
import moment from 'moment';
import { withFirebase } from "../../../utils/Firebase";
import { LANDING } from "../../../constants/routes";
import ReportMap from "./atoms/ReportMap";
import ReportInfo from "./atoms/ReportInfo";
import { CircularProgress } from "@material-ui/core";
import topojsonKeys from "../../../data/TopojsonKeys.json";
import monthNames from "../../../data/months.json";

// Consistent timestamp
const momentPrevHour = moment().utc()
// const momentPrevHour = moment().utc();
const momentPrevHourKey = moment(momentPrevHour)
  .utc()
  .local()
  .format("YYYY-MM-DD-HH");
const year = momentPrevHourKey.substring(0,4)
const month = momentPrevHourKey.substring(0,7)

const Report = ({ firebase, regionId, properties }) => {
  if (!regionId && typeof window !== "undefined") {
    navigate(LANDING);
  }
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState();
  const [adminLevel, setAdminLevel] = useState();
  const [subRegion, setSubRegion] = useState();
  const [tooltipContent, setTooltipContent] = useState();
  const [reportData, setReportData] = useState();
  const [regionReports, setRegionReports] = useState();
  const [topojson, setTopojson] = useState();
  const [currentProperties, setCurrentProperties] = useState(properties);
  const [restrictedRegions, setRestrictedRegions] = useState([]);
  const countryCode = selectedRegion ? selectedRegion.substring(0, 3) : "";
  const maxLevel = countryCode
    ? +topojsonKeys[countryCode].split("_")[2]
    : null;
  useEffect(() => {
    setShowTooltip(true);
    if (firebase && !selectedRegion && regionId) {
      selectRegion(regionId, properties);
    }
  }, [firebase, regionId]);

  // const adminLevel = useMemo(() => (region.match(/\./g) || []).length, [
  //   selectedRegion,
  // ]);

  const selectRegion = async (region, properties) => {
    setLoading(true);
    setSelectedRegion(region);
    setCurrentProperties(properties);
    const formattedRegion = region.replace(/\./g, "-");
    const aLevel = formattedRegion.match(/\-/g)
      ? formattedRegion.match(/\-/g).length
      : 0;
    setAdminLevel(aLevel);
    const countryCode = region.substring(0, 3);
    const regionKey = region.length === 3 ? region : region.replace(/\./g, "-");
    await firebase
      .topojson(countryCode, regionKey)
      .once("value")
      .then((snapshot) => {
        setTopojson(snapshot.val());
      })
      .catch((err) => console.log("topojson ERROR: ", err));
    await firebase
      .restrictedRegions(countryCode)
      .get()
      .then((response) =>
        response.data()
          ? setRestrictedRegions(response.data().regions)
          : setRestrictedRegions([])
      )
      .catch((err) => console.log(err));
    const currentReportRegion = region.replace(/-/g, ".");
    console.log("selectRegion", region, aLevel, maxLevel);
    if (aLevel + 1 === maxLevel) {
      console.log("getting all reports");
      const reportRegion = region.replace(/-/g, ".").split("_")[0];
      console.log("reportRegion", reportRegion);
      // const querySnap = collection.where(firebase.firestore.FieldPath.documentId(), '<', '100').get();
      const querySnap = await firebase
        .reports()
        .where("regionId", ">=", reportRegion)
        .where("regionId", "<=", `${reportRegion}a`)
        // .where(
        //   firebase.documentId.substring(0, reportRegion.length),
        //   ">=",
        //   reportRegion
        // )
        .get()
        .catch((err) => console.log(err));
      const reports = {};
      querySnap.forEach((doc) => {
        console.log ("querySnap", doc.data())
        return (reports[doc.id] = doc.data())
      });
      console.log("reports", reports);
      const repObj = await getReportObj(currentReportRegion);
      setReportData(repObj);
      setRegionReports(reports);
    } else {
      const repObj = await getReportObj(currentReportRegion);
      setReportData(repObj);
    }
    setLoading(false);
  };

  const getReportObj = async (regionId) => {
    // create reportObj
    const reportObj = {
      [year]: {},
      [month]: {},
      overall: {}
    }

    await firebase
    .report(regionId)
    .get()
    .then((doc) => {
      console.log(regionId, "report RETRIEVED");
      reportObj.overall = doc.data()
    });
    await firebase
    .timeBasedReport(regionId, year)
    .get()
    .then((doc) => {
      console.log(`${regionId} ${year} report RETRIEVED`);
      reportObj[year] = doc.data()
    });
    await firebase
    .timeBasedReport(regionId, month)
    .get()
    .then((doc) => {
      console.log(`${regionId} ${month} report RETRIEVED`);
      reportObj[month] = doc.data()
    });
    console.log('reportData', reportObj)
    return reportObj;
  }

  const getNameString = useMemo(() => {
    if (currentProperties) {
      return `${currentProperties.NAME_5 ? currentProperties.NAME_5 : ""}${
        currentProperties.NAME_5 ? ", " : ""
      }${currentProperties.NAME_4 ? currentProperties.NAME_4 : ""}${
        currentProperties.NAME_4 ? ", " : ""
      }${currentProperties.NAME_3 ? currentProperties.NAME_3 : ""}${
        currentProperties.NAME_3 ? ", " : ""
      }${currentProperties.NAME_2 ? currentProperties.NAME_2 : ""}${
        currentProperties.NAME_2 ? ", " : ""
      }${currentProperties.NAME_1 ? currentProperties.NAME_1 : ""}${
        currentProperties.NAME_1 ? ", " : ""
      }${currentProperties.NAME_0}`;
    }
    return "...";
  }, [currentProperties]);

  const getLocalNameString = useMemo(() => {
    if (currentProperties && currentProperties.NL_NAME_1) {
      return `${
        currentProperties.NL_NAME_5 ? currentProperties.NL_NAME_5 : ""
      }${currentProperties.NL_NAME_5 ? ", " : ""}${
        currentProperties.NL_NAME_4 ? currentProperties.NL_NAME_4 : ""
      }${currentProperties.NL_NAME_4 ? ", " : ""}${
        currentProperties.NL_NAME_3 ? currentProperties.NL_NAME_3 : ""
      }${currentProperties.NL_NAME_3 ? ", " : ""}${
        currentProperties.NL_NAME_2 ? currentProperties.NL_NAME_2 : ""
      }${currentProperties.NL_NAME_2 ? ", " : ""}${
        currentProperties.NL_NAME_1 ? currentProperties.NL_NAME_1 : ""
      }${currentProperties.NL_NAME_1 ? ", " : ""}${currentProperties.NAME_0}`;
    }
    return null;
  }, [currentProperties]);

  const locationsVisited = useMemo(() => {

    // create locationsObj
    const locationsObj = {
      [year]: {},
      [month]: {},
      overall: {}
    }
    if (reportData) {
      // set overall locations
      const {
        accepted,
        delivered,
        have,
        unfinished,
        need,
        rejected,
      } = reportData.overall;
      const oa = accepted ? +accepted : 0;
      const od = delivered ? +delivered : 0;
      const oh = have ? +have : 0;
      const ou = unfinished ? +unfinished : 0;
      const on = need ? +need : 0;
      const or = rejected ? +rejected : 0;
      locationsObj.overall =  oa+ od + oh + ou + on + or;
      if (!reportData[year]) return locationsObj;
      // set yearly locations
      const ya = reportData[year].accepted ? +reportData[year].accepted : 0;
      const yd = reportData[year].delivered ? +reportData[year].delivered : 0;
      const yh = reportData[year].have ? +reportData[year].have : 0;
      const yu = reportData[year].unfinished ? +reportData[year].unfinished : 0;
      const yn = reportData[year].need ? +reportData[year].need : 0;
      const yr = reportData[year].rejected ? +reportData[year].rejected : 0;
      locationsObj[year] =  ya+ yd + yh + yu + yn + yr;
      if (!reportData[month]) return locationsObj;
      // set monthly locations
      const ma = reportData[month].accepted ? +reportData[month].accepted : 0;
      const md = reportData[month].delivered ? +reportData[month].delivered : 0;
      const mh = reportData[month].have ? +reportData[month].have : 0;
      const mu = reportData[month].unfinished ? +reportData[month].unfinished : 0;
      const mn = reportData[month].need ? +reportData[month].need : 0;
      const mr = reportData[month].rejected ? +reportData[month].rejected : 0;
      locationsObj[month] =  ma+ md + mh + mu + mn + mr;
    }
    return locationsObj;
  }, [reportData]);

  return (
    <div>
      <div className="reportRegionTitle">
        {currentProperties ? (
          <div className="regionTitle">
            {currentProperties.NAME_5 && adminLevel === 5 ? (
              <span>{currentProperties.NAME_5}</span>
            ) : (
              ""
            )}
            {currentProperties.NAME_5 && adminLevel === 5 ? ", " : ""}
            {currentProperties.NAME_4 && adminLevel >= 4 ? (
              <span
                style={adminLevel > 4 ? { textDecoration: "underline" } : {}}
                onClick={
                  adminLevel > 4
                    ? () => {
                        const {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                          GID_3,
                          NAME_3,
                          NL_NAME_3,
                          GID_4,
                          NAME_4,
                          NL_NAME_4,
                        } = currentProperties;
                        const updatedProperties = {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                          GID_3,
                          NAME_3,
                          NL_NAME_3,
                          GID_4,
                          NAME_4,
                          NL_NAME_4,
                        };
                        selectRegion(
                          currentProperties.GID_4,
                          updatedProperties
                        );
                      }
                    : () => {}
                }
              >
                {currentProperties.NAME_4}
              </span>
            ) : (
              ""
            )}
            {currentProperties.NAME_4 && adminLevel >= 4 ? ", " : ""}
            {currentProperties.NAME_3 && adminLevel >= 3 ? (
              <span
                style={adminLevel > 3 ? { textDecoration: "underline" } : {}}
                onClick={
                  adminLevel > 3
                    ? () => {
                        const {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                          GID_3,
                          NAME_3,
                          NL_NAME_3,
                        } = currentProperties;
                        const updatedProperties = {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                          GID_3,
                          NAME_3,
                          NL_NAME_3,
                        };
                        selectRegion(
                          currentProperties.GID_3,
                          updatedProperties
                        );
                      }
                    : () => {}
                }
              >
                {currentProperties.NAME_3}
              </span>
            ) : (
              ""
            )}
            {currentProperties.NAME_3 && adminLevel >= 3 ? ", " : ""}
            {currentProperties.NAME_2 && adminLevel >= 2 ? (
              <span
                style={adminLevel > 2 ? { textDecoration: "underline" } : {}}
                onClick={
                  adminLevel > 2
                    ? () => {
                        const {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                        } = currentProperties;
                        const updatedProperties = {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                          GID_2,
                          NAME_2,
                          NL_NAME_2,
                        };
                        selectRegion(
                          currentProperties.GID_2,
                          updatedProperties
                        );
                      }
                    : () => {}
                }
              >
                {currentProperties.NAME_2}
              </span>
            ) : (
              ""
            )}
            {currentProperties.NAME_2 && adminLevel >= 2 ? ", " : ""}
            {currentProperties.NAME_1 && adminLevel >= 1 ? (
              <span
                style={adminLevel > 1 ? { textDecoration: "underline" } : {}}
                onClick={
                  adminLevel > 1
                    ? () => {
                        const {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                        } = currentProperties;
                        const updatedProperties = {
                          GID_0,
                          NAME_0,
                          GID_1,
                          NAME_1,
                          NL_NAME_1,
                        };
                        selectRegion(
                          currentProperties.GID_1,
                          updatedProperties
                        );
                      }
                    : () => {}
                }
              >
                {currentProperties.NAME_1}
              </span>
            ) : (
              ""
            )}
            {currentProperties.NAME_1 && adminLevel >= 1 ? ", " : ""}
            {currentProperties.NAME_0 && adminLevel >= 0 ? (
              <span
                style={adminLevel > 0 ? { textDecoration: "underline" } : {}}
                onClick={
                  adminLevel > 0
                    ? () => {
                        const { GID_0, NAME_0 } = currentProperties;
                        const updatedProperties = {
                          GID_0,
                          NAME_0,
                        };
                        selectRegion(
                          currentProperties.GID_0,
                          updatedProperties
                        );
                      }
                    : () => {}
                }
              >
                {currentProperties.NAME_0}
              </span>
            ) : (
              ""
            )}
          </div>
        ) : null}
        {getLocalNameString ? (
          <div className="regionTitle">
            {getLocalNameString || "...loading..."}
          </div>
        ) : null}
        {restrictedRegions.length > 0 ? (
          <div className="restrictedRegions">
            <p>(For security purposes this map may not show all the data)</p>
          </div>
        ) : null}
      </div>
      {loading ? (
        <div className="progressCircle">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="mapNTotalDiv">
            <div className="reportMapBackDiv">
              {console.log("showTooltip", showTooltip)}
              {typeof window !== `undefined` && topojson && showTooltip && (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "70vh",
                    maxHeight: "100%",
                    border: "solid 5px #1F79BC",
                    backgroundColor: "white",
                    borderRadius: 15,
                    margin: 10,
                    minWidth: 300,
                  }}
                >
                  <ReportMap
                    setSubRegion={setSubRegion}
                    selectRegion={selectRegion}
                    selectedRegion={selectedRegion}
                    level={adminLevel}
                    reportData={reportData}
                    regionReports={regionReports}
                    countryCode={countryCode}
                    restrictedRegions={restrictedRegions}
                    topojson={topojson}
                    regions={
                      reportData && reportData.overall.activeRegions
                        ? reportData.overall.activeRegions
                        : []
                    }
                    setTooltipContent={setTooltipContent}
                  />
                  <ReactTooltip>{tooltipContent}</ReactTooltip>
                </div>
              )}
            </div>
            {reportData ? (
              <div className="totalCountReport">
                <p className="count">
                  <span className="countNumber">
                    {reportData.overall.bibles
                      ? reportData.overall.bibles.toLocaleString()
                      : 0}
                  </span>
                  <span className="countTextDiv">
                    <span className="countText">Bibles shared</span>
                  </span>
                  {reportData &&
                  reportData[month] &&
                  reportData[month].bibles > 0 ? (
                    <span className="timeBasedDiv">
                      <span className="timeBasedTextDiv bottomMargin">
                        <span className="timeBasedTextHeader">
                          {monthNames[month.substring(5, 7)]}:
                        </span>
                        <span className="timeBasedText">
                          +
                          {reportData[month].bibles
                            ? reportData[month].bibles.toLocaleString()
                            : "+0"}
                        </span>
                      </span>
                      <span className="timeBasedTextDiv">
                        <span className="timeBasedTextDiv bottomMargin">
                          <span className="timeBasedTextHeader">{year}:</span>
                          <span className="timeBasedText">
                            +{reportData[year].bibles.toLocaleString() || "+0"}
                          </span>
                        </span>
                      </span>
                      <span className="timeBasedTextDiv">
                        <span className="timeBasedTextHeader">All time:</span>
                        <span className="timeBasedText">
                          {reportData
                            ? console.log("reportData#1:", reportData)
                            : null}
                          +{reportData.overall.bibles.toLocaleString() || "+0"}
                        </span>
                      </span>
                    </span>
                  ) : null}
                </p>
                <div className="countDivider" />
                <p className="count">
                  <span className="countNumber">
                    {locationsVisited.overall.toLocaleString()}
                  </span>
                  <span className="countTextDiv">
                    <span className="countText">Locations visited</span>
                  </span>
                  {locationsVisited &&
                  locationsVisited[month] &&
                  locationsVisited[month] > 0 ? (
                    <span className="timeBasedDiv">
                      <span className="timeBasedTextDiv bottomMargin">
                        <span className="timeBasedTextHeader">
                          {monthNames[month.substring(5, 7)]}:
                        </span>
                        <span className="timeBasedText">
                          +{locationsVisited[month].toLocaleString() || "..."}
                        </span>
                      </span>
                      <span className="timeBasedTextDiv">
                        <span className="timeBasedTextDiv bottomMargin">
                          <span className="timeBasedTextHeader">{year}:</span>
                          <span className="timeBasedText">
                            +{locationsVisited[year].toLocaleString() || "..."}
                          </span>
                        </span>
                      </span>
                      <span className="timeBasedTextDiv">
                        <span className="timeBasedTextHeader">All time:</span>
                        <span className="timeBasedText">
                          {reportData
                            ? console.log(
                                "locationsVisited#1:",
                                locationsVisited
                              )
                            : null}
                          +{locationsVisited.overall.toLocaleString() || "+0"}
                        </span>
                      </span>
                    </span>
                  ) : null}
                </p>
              </div>
            ) : null}
          </div>
          {reportData ? (
            <div>
              <ReportInfo reportData={reportData.overall} />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default withFirebase(Report);
