import React, { useMemo } from "react";
import HorizontalBar from "./HorizontalBar";
import MinistriesList from "./MinistriesList";

const ReportInfo = ({ reportData }) => {
  const { resources, ministries, neededResources } = reportData;
  console.log("[ReportInfo.js] reportData = ", reportData);

  // const locationsVisited = useMemo(() => {
  //   const a = accepted ? +accepted : 0;
  //   const d = delivered ? +delivered : 0;
  //   const h = have ? +have : 0;
  //   const u = unfinished ? +unfinished : 0;
  //   const n = need ? +need : 0;
  //   const r = rejected ? +rejected : 0;
  //   return a + d + h + u + n + r;
  // }, [reportData]);

  return (
    <div>
      <div className="reportCharts">
        <div className="horizontalChart">
          <HorizontalBar data={reportData} />
        </div>
        <div className="ministriesList">
          <MinistriesList ministries={ministries} />
        </div>
      </div>
    </div>
  );
};

export default ReportInfo;
