import React from "react";
import Layout from "../utils/layout";
import Report from "../components/scenes/Report/Report";

export default ({ location }) => {
  console.log("report.js LOCATION", location);
  const { state } = location;
  const regionId = state ? state.regionId : null;
  const properties = state ? state.properties : null;

  return (
    <Layout>
      <Report regionId={regionId} properties={properties} />
    </Layout>
  );
};
