import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { withFirebase } from "../../../../utils/Firebase";
import { PUBLIC_MINISTRY } from "../../../../constants/routes";

const MinistriesList = ({ firebase, ministries }) => {
  const [ministriesData, setMinistriesData] = useState();
  let sortedMinistries = [];
  for (const ministry in ministries) {
    if (ministry !== "undefined" && ministry) {
      sortedMinistries.push([ministry, ministries[ministry]]);
    }
  }
  sortedMinistries.sort(function (a, b) {
    return a[1] - b[1];
  });

  const newMinistryArray = sortedMinistries.map((m) => m[0]);
  console.log("newMinistryArray", newMinistryArray);

  useEffect(() => {
    if (firebase && newMinistryArray.length > 0 && !ministriesData) {
      getMinistriesData(newMinistryArray);
    }
  }, [firebase, ministries]);

  const getMinistriesData = async (ministryArray) => {
    let newMinistryArray = ministryArray;
    if (ministryArray.length > 10) {
      newMinistryArray = ministryArray.slice(0,9)
    }
    const ministriesList = await firebase
      .ministries()
      .where("ministryId", "in", newMinistryArray)
      .get();
    const fullDataMinistries = {};
    ministriesList.forEach((doc) => {
      fullDataMinistries[doc.id] = doc.data();
    });
    console.log("fullDataMinistries", fullDataMinistries);
    setMinistriesData(fullDataMinistries);
  };

  return (
    <table className="resources__table">
      <tbody>
        <tr className="resources__table__row">
          <th className="resources__table__cell">Ministry</th>
          <th className="resources__table__cell">Bibles Shared</th>
        </tr>
        {ministriesData &&
          newMinistryArray &&
          newMinistryArray.reverse().map((m) => {
            if (!ministriesData[m]) return null;
            return (
              <tr key={m} className="resources__table__row">
                <td className="resources__table__cell__centerText">
                  {ministriesData[m].public ? (
                    <Link
                      key={ministriesData[m].ministryId}
                      to={PUBLIC_MINISTRY}
                      state={{ ministryId: ministriesData[m].ministryId }}
                    >
                      {ministriesData[m].name}
                    </Link>
                  ) : (
                    "Private Ministry"
                  )}
                </td>
                <td className="resources__table__cell__centerText">
                  {ministries[m].toLocaleString()}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default withFirebase(MinistriesList);
